// Loading.js
import React from 'react';

function LoadingScene() {
    return (
        <div id="loadingBackground">
            <div class="loadingio-spinner-spinner-rxqqkcgwa7m"><div class="ldio-i5cxovbpe1">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
        </div>
    );
}

export default LoadingScene;