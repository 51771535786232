// import React from "react";
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import "./App.css";
import TimeCheck_wrapper from './timeCheck';
import React, { useRef, useState, useEffect} from 'react';
import db from "./firebase";
import { doc, getDoc } from 'firebase/firestore';
import LoadingScene from './loading';
import imgLogo from './logo.png';
import coinImg from './coin.png';

var targetDateObj = new Date('2024-03-29T09:58:00')
var targetDateUnixTime = targetDateObj.getTime();
var couponReleaseDateUnixTime = new Date('2024-04-17T00:00:00').getTime();
var showSearch = false;

if (new Date().getTime() >= targetDateUnixTime) { // 타겟 시간보다 현재 시간이 크면.
    showSearch = true;
} else {
    showSearch = false;
}

function App() {
  return (
    <div class="App">
      {/* navigateToPointResult 함수를 통해 path를 바꿔주면, 다른 내용을 렌더링한다. 이렇게
      이렇게 React의 SPA에서 화면 이동을 구현할 수 있다. */}
      <Routes>
        <Route path= "/" element={<InputAndSearch />} />
        <Route path= "/PointResult" element={<PointResult />} /> 
        <Route path= "/CouponDisplay" element={<CouponDisplay />} />
      </Routes>
    </div>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // 에러를 캐치해서 에러 메시지와 함께 컴포넌트를 다시 렌더링한다.
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }
  
  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>오류가 발생했습니다. 잠시 후에 다시 시도해주세요.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }  
}

function CouponDisplay() {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const day7_dlvry_coupon = location.state?.day7_dlvry_coupon;
  const oil_coupon_100k = location.state?.oil_coupon_100k;

  function handleCopy(targetId) { // targetId를 parameter로 받아서 찾는다.
    const couponCode = document.getElementById(targetId).textContent;
    navigator.clipboard.writeText(couponCode).then(() => {
      setShowPopup(true);
      setTimeout(() => {setShowPopup(false);}, 1000); // 전환시간 1s
    });
  };

  function openWindowOnButton(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  function conponDisplay(couponCode) {
    if (new Date().getTime() < couponReleaseDateUnixTime) {
      return <button class="couponPageButton">4/17 ~ 4/23에 다운로드 가능!</button>
    } else {
      if (couponCode != 0) {
        return  <button class="couponPageButton" onClick={() => openWindowOnButton(couponCode)}>쿠폰 다운로드 받기</button>
      } else {
        return <button class="couponPageButton">쿠폰 다운로드 대상자가 아니시네요!</button>
      }
    }
  };
  
  return (
    <div>
      <div class={showPopup ? "overlay overlayShow" : "overlay"}>
        <div class="popupContent">
          <span id="isCopied">복사되었습니다.</span>
        </div>
      </div>
      <div id="couponContainer">
        <div class="couponBox">
          <div class="couponContents">
            <p class="couponTitle">블랙비자칼, 그라비티 30% 할인 쿠폰</p>
            <p class="couponDesc">사용기간: 4월 27일까지</p>
            <div class="couponCodeContainer">
              <span class="couponCode" id="coupon1">gosu2403</span>
              <span class="copy" onClick={() => handleCopy('coupon1')}>쿠폰코드 복사하기</span>
            </div>
          </div>
          <button class="couponPageButton" onClick={() => openWindowOnButton('https://shop.bikemart.co.kr/event/coupon?seq=507')}>구매하러 가기</button>
        </div>
        <div class="mobileCouponBox">
          <div class="mobileCouponContents">
            <p class="couponTitle">10만원 GS칼텍스 주유권</p>
            <ul class="couponDesc">
              <li>- 배&달 클럽 고수 포인트 달성 시, 추첨을 통해 50분께 제공</li>
              <li>- 4/17 ~ 4/23동안 다운로드 가능</li>
              <li>- 1인 1회 다운로드 가능, 링크 유출 시 쿠폰 재발행 불가</li>
              <li>- 유효기간 만료 후 연장 또는 재발행 불가</li>
            </ul>
          </div>
          {conponDisplay(oil_coupon_100k)}
        </div>
        <div class="mobileCouponBox">
          <div class="mobileCouponContents">
            <p class="couponTitle">7일 이상 배달한 모두에게 드리는 주유권</p>
            <ul class="couponDesc">
              <li>- 배&달 클럽 5만원, 고&수 클럽 3만원 제공</li>
              <li>- 4/17 ~ 4/23동안 다운로드 가능</li>
              <li>- 1인 1회 다운로드 가능, 링크 유출 시 쿠폰 재발행 불가</li>
              <li>- 유효기간 만료 후 연장 또는 재발행 불가</li>
            </ul>
          </div>
          {conponDisplay(day7_dlvry_coupon)}
        </div>
      </div>
    </div>
  )
}

function PointResult() {
  const location = useLocation();
  const point = location.state?.point; // 이전 페이지에서 넘어온 변수에 접근.
  const id = location.state?.id;
  //const couponCode = location.state?.couponCode;
  const day7_dlvry_coupon = location.state?.day7_dlvry_coupon;
  const oil_coupon_100k = location.state?.oil_coupon_100k;
  const navigate = useNavigate();
  function toCouponPageClick() {
    navigate('/CouponDisplay', {state: {point: point, id: id, day7_dlvry_coupon: day7_dlvry_coupon, oil_coupon_100k: oil_coupon_100k}});
  }
  return (
    <div id="pointResultContainer">
      <p id="idDisplay"> {id} 님 고수 포인트 </p>
      <div id="pointContainer">
        <span id="pointDisplay"> {point} </span>
        <img id="coinImg" src={coinImg} alt="-" />
      </div>
      <div id="btnWrapperCoupon">
        <button class="btn" id="couponBtn" onClick={toCouponPageClick}>혜택 다운로드 받기</button>
      </div>
      <div id="warningContainer">
        <span class="symbol">※</span><span id="upperText"> 비정상적인 방법으로 이용하거나 타인의 정보를 도용할 경우</span>
        <span class="indent"></span><span id="underText">법적 처벌의 대상이 될 수 있습니다.</span>
      </div>
    </div>
  );
}

function InputAndSearch () {
    const navigate = useNavigate();
    const inputIdRef = useRef(null);
    const inputHpRef = useRef(null);
    const infoInitializeStrMin = '\u00A0'
    const infoInitializeStr = infoInitializeStrMin.repeat(1);
    // const [point, setPoint] = useState(infoInitializeStr);
    const [id, setId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const targetMonth = targetDateObj.getMonth() + 1; // Adding 1 because getMonth() returns 0 for January, 1 for February, etc.
    const targetDay = targetDateObj.getDate();

    async function handleClick() { // 고수포인트 조회 버튼을 누르면 호출되는 함수
      setLoading(true); // api 호출 전에 true로 변경하여 로딩화면 띄우기
      let inputId = inputIdRef.current.value; // 현재 input에 입력된 Id 값 받아옴
      let inputHp = inputHpRef.current.value;

      if (inputId == "") { // Id 입력을 안 했다면.
        setLoading(false);
        setId(infoInitializeStr);
        // setPoint(infoInitializeStr);
        setErrorMessage("배민커넥트 ID를 \n입력해주세요!");
        setShowPopup(true);
        setTimeout(() => {setShowPopup(false);}, 1500); // 전환시간 1.5s
        // console.log("배민커넥트 ID를 입력해주세요!");
      } else {
        var docRef = doc(db, process.env.REACT_APP_COLLECTION, inputId);
        
        try {
          var docSnap = await getDoc(docRef);
        } catch (e) {
          setLoading(false);
          alert( "오류가 발생하였습니다. 잠시 후에 다시 시도해주세요. " + e);
          setErrorMessage(e);
        }

        if (docSnap.exists()) {
          if (docSnap.data()['hp'] == inputHp) {
            setLoading(false);
            setErrorMessage("")
            const point = docSnap.data()['point'];
            const id = inputId;
            // const couponCode = docSnap.data()['coupon']
            const day7_dlvry_coupon = docSnap.data()['day7_dlvry_coupon']
            const oil_coupon_100k = docSnap.data()['oil_coupon_100k']
            setId(inputId);
            navigate('/pointResult', {state: {point: point, id: id, day7_dlvry_coupon: day7_dlvry_coupon, oil_coupon_100k: oil_coupon_100k}}); //pointResult 페이지로 이동한다. state에 firestore에서 가져온 변수들을 들고 간다.

          } else {
            setLoading(false);
            setId(infoInitializeStr);
            setErrorMessage("핸드폰 번호가 잘못되었거나 대상자가 아닙니다.");
            setShowPopup(true);
            setTimeout(() => {setShowPopup(false);}, 1500); // 전환시간 1.5s
            console.log("핸드폰 번호가 잘못되었거나 \n대상자가 아닙니다.");
          }
        } else {
          setLoading(false);
          setId(infoInitializeStr);
          setShowPopup(true);
          setTimeout(() => {setShowPopup(false);}, 1500); // 전환시간 1.5s
          setErrorMessage("배민커넥트 ID가 잘못되었거나 \n대상자가 아닙니다.");
        }
      }
    }

  return (
    <div>
      <div class={showPopup ? "overlay overlayShow" : "overlay"}>
        <div class="popupContent" id="errorContainer">
          <span id="errorMessage">{errorMessage}</span>
        </div>
      </div>
      {loading ? <LoadingScene /> : null}
      <div id = "logoContainer">
            <img id="bdgsLogo" src={imgLogo} alt="-" />
      </div>
      <ErrorBoundary>
        <div id="searchArea">
            <div class="input-container">
              <label for="inputId">배민커넥트 ID</label>
              <input class="input" ref={inputIdRef} name="inputId" placeholder="배민커넥트 로그인 ID를 입력해주세요"/>
            </div>
            <div class="input-container">
              <label for="inputHp">휴대폰 번호</label>
              <input class="input" ref={inputHpRef} name="inputHp" placeholder="하이픈(-) 없이 숫자만 입력해주세요"/>
            </div>
            <div id="btnWrapper">
              {showSearch ? <button class="btn" id="searchBtn" onClick={handleClick}>고수포인트 조회하기</button> : <button class="btn" id="searchBtn">{`${targetMonth}월 ${targetDay}일 10시부터 조회할 수 있어요!`}</button>}
            </div>
        </div>
      </ErrorBoundary>
      <div id="instructions">
          <p id="insTitle"><b>유의사항</b></p>
          <ul id = "insUl">
            <li><span id = "insContent"><b>2분기 배달고수클럽 대상자</b>분들만 이용이 가능합니다.</span></li>
            <li><span id = "insContent">전날 데이터가 <b>매일 오전 10시에 업데이트</b> 됩니다.</span></li>
            <li><span id = "insContent">비정상적인 방법으로 본 페이지를 이용할 경우 접속이 차단될 수 있습니다.</span></li>
          </ul>
        </div>
    </div>
  );
}

export default App;