import InputAndSearch from './searchScript';

var targetDate = new Date('2001-12-19T09:50:00');
var showSearch = false;

if (new Date() >= targetDate) {
    showSearch = true;
} else {
    showSearch = false;
} 

function TimeCheck_wrapper() {
    return (
        <div>
            {showSearch ? <InputAndSearch />: <p id="afterTimeCheck"><b>배달고수 이벤트가 종료되었습니다!<br />감사합니다!</b></p>}
        </div>
    );
}

export default TimeCheck_wrapper;