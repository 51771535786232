import React, { useRef, useState } from 'react';
import db from "./firebase";
import { doc, getDoc } from 'firebase/firestore';
import LoadingScene from './loading';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>오류가 발생했습니다. 잠시 후에 다시 시도해주세요.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

function InputAndSearch () {
    const inputIdRef = useRef(null);
    const inputHpRef = useRef(null);
    const infoInitializeStrMin = '\u00A0'
    const infoInitializeStr = infoInitializeStrMin.repeat(1);
    const [point, setPoint] = useState(infoInitializeStr);
    const [id, setId] = useState(infoInitializeStr);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleClick() { // 고수포인트 조회 버튼을 누르면 호출되는 함수
      setLoading(true); // api 호출 전에 true로 변경하여 로딩화면 띄우기
      let inputId = inputIdRef.current.value;
      let inputHp = inputHpRef.current.value;

      if (inputId == "") {
        setLoading(false);
        setId(infoInitializeStr);
        setPoint(infoInitializeStr);
        setErrorMessage("배민커넥트 ID를 입력해주세요!");
        console.log("배민커넥트 ID를 입력해주세요!");
      } else {
        var docRef = doc(db, process.env.REACT_APP_COLLECTION, inputId);
        
        try {
          var docSnap = await getDoc(docRef);
        } catch (e) {
          setLoading(false);
          alert( "오류가 발생하였습니다. 잠시 후에 다시 시도해주세요. " + e);
          setErrorMessage(e);
        }

        if (docSnap.exists()) {
          if (docSnap.data()['hp'] == inputHp) {
            setLoading(false);
            setErrorMessage("")
            setPoint(docSnap.data()['point']);
            setId(inputId);

          } else {
            setLoading(false);
            setId(infoInitializeStr);
            setPoint(infoInitializeStr);
            setErrorMessage("핸드폰 번호가 잘못되었거나 대상자가 아닙니다.");
            console.log("핸드폰 번호가 잘못되었거나 대상자가 아닙니다.");
          }
        } else {
          setLoading(false);
          setId(infoInitializeStr);
          setPoint(infoInitializeStr);
          setErrorMessage("배민커넥트 ID가 잘못되었거나 대상자가 아닙니다.");
          console.log("배민커넥트 ID가 잘못되었거나 대상자가 아닙니다.");
        }
      }
    }

  return (
    <div>
      {loading ? <LoadingScene /> : null}
      <ErrorBoundary>
        <div id="searchArea">
            <div class="input-container">
              <label for="inputId">배민커넥트 ID</label>
              <input class="input" ref={inputIdRef} name="inputId" placeholder="배민커넥트 로그인 ID를 입력해주세요"/>
            </div>
            <div class="input-container">
              <label for="inputHp">휴대폰 번호</label>
              <input class="input" ref={inputHpRef} name="inputHp" placeholder="하이픈(-) 없이 숫자만 입력해주세요"/>
            </div>
            <div id="btnWrapper">
              <button class="btn" onClick={handleClick}>고수포인트 조회하기</button>
            </div>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default InputAndSearch;