import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID
};

// Use this to initialize the firebase App
const app = initializeApp(firebaseConfig);
// Use these for db
const db = getFirestore(app);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
/* eslint-disable-next-line no-restricted-globals */
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_REPRO),
//     isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
//   }); 

// getToken(appCheck).then(() => {
//     console.log('success')
// }).catch((error) => {
//     console.log(error.message)
// })

export default db;